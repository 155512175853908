import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`RoundButton`}</inlineCode>{` is used for actions that require attention or result in pages navigation. Use `}<inlineCode parentName="p">{`icon`}</inlineCode>{` property to set an icon, for example, "chevron-left" for back button. See all available icons list `}<a parentName="p" {...{
        "href": "/atoms/Icon"
      }}>{`Icon`}</a></p>
    <h2>{`Default examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<div style={{display: 'flex', gap: '12px', alignItems: 'center' }}>
  <RoundButton icon="chevron-left" />
  <RoundButton icon="chevron-left" variant="filled" scale="sm" />
  <RoundButton icon="chevron-left" variant="outline" scale="lg" />
  <RoundButton icon="chevron-left" variant="lifted" scale="xl" />
</div>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p><inlineCode parentName="p">{`Button`}</inlineCode>{` component get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, `}<inlineCode parentName="p">{`TYPOGRAPHY`}</inlineCode>{`, `}<inlineCode parentName="p">{`POSITION`}</inlineCode>{` and `}<inlineCode parentName="p">{`BORDER`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p>{`Native `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` HTML attributes are forwarded to the underlying React `}<inlineCode parentName="p">{`button`}</inlineCode>{` component and are not listed below.`}</p>
    <h3>{`Some notable system props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets the HTML tag for the component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`display`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`explicitly sets the display method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number or String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`explicitly sets the width`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number or String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`explicitly sets the height`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Main component props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'filled'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a value of `}<inlineCode parentName="td">{`filled`}</inlineCode>{`, `}<inlineCode parentName="td">{`outline`}</inlineCode>{` and `}<inlineCode parentName="td">{`lifted`}</inlineCode>{` results in different button styles`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`scale`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'md'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a value of `}<inlineCode parentName="td">{`sm`}</inlineCode>{`, `}<inlineCode parentName="td">{`md`}</inlineCode>{`, `}<inlineCode parentName="td">{`lg`}</inlineCode>{` and `}<inlineCode parentName="td">{`xl`}</inlineCode>{` results in different button sizes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`name of `}<a parentName="td" {...{
              "href": "/atoms/Icon"
            }}>{`Icon`}</a></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      